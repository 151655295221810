import React from "react"
import Layout from "../../components/Layout/Layout"
import Seo from "../../components/Core/Seo"
import { graphql } from "gatsby"
import styled from "styled-components"
import ContentContainer from "../../components/Core/Containers/ContentContainer"
import ActivateForm from "../../components/Accounts/Activate/ActivateForm"
import breakpoints from "../../styles/breakpoints"

const LoginContainer = styled.div`
  padding: 0 0 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  @media (min-width: ${breakpoints.md}) {
    padding: 220px 0;
  }
`

const ActivatePage = ({ location }) => {
  const path = location.pathname

  return (
    <Layout transparentHeader={true}>
      <Seo
        path={path}
        title={"Activate Account"}
        description={"Jupiter account activation"}
        fullTitle={false}
      />
      <ContentContainer>
        <LoginContainer>
          <ActivateForm />
        </LoginContainer>
      </ContentContainer>
    </Layout>
  )
}

export default ActivatePage