import React, { useEffect, useState } from "react"
import styled from "styled-components"
import breakpoints from "../../../styles/breakpoints"
import fonts from "../../../styles/fonts"
import colors from "../../../styles/colors"
import { ButtonStyleDark } from "../../Core/Buttons/Styles"
import TextButton from "../../Core/Buttons/TextButton"
import { InputContainerStyle } from "../Styles"
import axios from "axios"
import { getUserErrorMessage } from "../../../services/core/accountFunctions"

const FormContainer = styled.div`
  ${fonts.garamondRegular};
  max-width: 423px;
  margin: auto;
  width: 100%;
`

const Title = styled.h1`
  ${fonts.garamondRegular};
  color: ${colors.charcoal};
  font-size: 32px;
  margin-top: .67em;
  margin-bottom: 30px;
  text-align: center;
  line-height: 1.15;

  @media (min-width: ${breakpoints.md}) {
    font-size: 40px;
    letter-spacing: normal;
  }
`

const InputContainer = styled.div`
  ${InputContainerStyle}
`

const SubmitButton = styled.button`
  ${ButtonStyleDark};
  max-width: unset;
`

const Navigations = styled.div`
  margin-top: 40px;
  display: grid;
  grid-gap: 30px;
  justify-content: center;
  text-align: center;

  a {
    letter-spacing: 1px;
    span {
      padding-bottom: 6px;
    }
  }
`

const RegisterButtonContainer = styled.div`
  display: grid;
`

const Claimer = styled.div`
  ${fonts.sofiaPro};
  margin-top: 4px;
  font-size: 12px;
`

const ServerError = styled.p`
  font-size: 14px;
  letter-spacing: .25px;
  color: #c00;
`

const ActivateForm = () => {
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [activationUrl, setActivationUrl] = useState("")
  const [submitText, setSubmitText] = useState("Activate Account")
  const [error, setError] = useState("")
  const [isFormSuccess, setIsFormSuccess] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const currentUrl = new URL(window.location.href)
      let shopifyUrl = currentUrl.search.replace("?reset_url=", "")

      shopifyUrl = shopifyUrl.replaceAll("%2F", "/");
      shopifyUrl = shopifyUrl.replaceAll("%3A", ":");

      const syclidPosition = shopifyUrl.indexOf("&syclid");

      if (syclidPosition > 0) {
        shopifyUrl = shopifyUrl.substring(0, syclidPosition);
      }

      setActivationUrl(shopifyUrl)
    }
  }, [])

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (isFormSuccess) {
      window.location.href = "https://hellojupiter.com/account/";
    }

    setSubmitText("Processing...")

    const url = '/.netlify/functions/shopifyUpdate'

    const confirmedPassword = password === confirmPassword ? password : false

    if (confirmedPassword) {

      const data = {
        "mutationType": "activateAccount",
        "data": {
          "password": confirmedPassword,
          "activationUrl": activationUrl
        }
      }

      try {
        const response = await axios.post(url, data)
  
        if (response.data.response.customerActivateByUrl.customerUserErrors.length === 0) {
          setIsFormSuccess(true);
          setSubmitText("Account activated! Click here to login")
        } else {
          const errorMessage = getUserErrorMessage(response.data.response.customerActivateByUrl.customerUserErrors[0].message)
          setError(errorMessage)
          setSubmitText("Activate Account")
        }
      } catch {
        setError("Server error")
        setSubmitText("Activate Account")
      }
    } else {
      setError("Error: Passwords must match")
      setSubmitText("Save")
    }
  }

  return (
    <FormContainer>
      <Title>Activate Account</Title>
      <form onSubmit={handleSubmit}>
        <InputContainer>
          <ServerError>{error}</ServerError>
        </InputContainer>
        <InputContainer>
          <label>Password</label>
          <input
            type="password"
            placeholder="Password"
            name="customer[password]"
            autoCapitalize="off"
            autoComplete="password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </InputContainer>
        <InputContainer>
          <label>Confirm Password</label>
          <input
            type="password"
            placeholder="Confirm Password"
            name="customer[confirmPassword]"
            autoCapitalize="off"
            autoComplete="password"
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </InputContainer>
        <SubmitButton type={"submit"}>{submitText}</SubmitButton>
      </form>
      <Navigations>
        <TextButton to="/account/">Already have an account?</TextButton>
      </Navigations>
    </FormContainer>
  )
}

export default ActivateForm